import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'lodash-es';
import { FuseConfirmationConfig } from './confirmation.types';
import { FuseConfirmationDialogComponent } from './dialog/dialog.component';

@Injectable({
    providedIn: 'root'
})
export class FuseConfirmationService {
    private _matDialog: MatDialog;
    private _defaultConfig: FuseConfirmationConfig = {
        title: 'Confirm action',
        message: 'Are you sure you want to confirm this action?',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation-triangle',
            color: 'warn',
        },
        actions: {
            confirm: {
                show: true,
                label: 'Confirm',
                color: 'warn',
            },
            cancel: {
                show: true,
                label: 'Cancel',
            },
        },
        dismissible: false,
    };

    constructor(matDialog: MatDialog) {
        this._matDialog = matDialog;
    }

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent> {
        const userConfig = merge({}, this._defaultConfig, config);

        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'fuse-confirmation-dialog-panel',
        });
    }
}
